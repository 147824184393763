
.textLeft {
  font-size: large;
  text-align: left;
  width: 100%;
}

.textCenter {
  font-size: large;
  text-align: center;
  width: 100%;
}

.textRight {
  font-size: large;
  text-align: right;
  width: 100%;
}

.textLink {
  color: #00A6FF;
  text-decoration: none;
}

.boxInfoHeader{
  margin: auto;
  padding: 10px;
  color: #000;
  font-size: 18px;
  border: 1px solid #58e687;
  background-color: #FCFCFC;
  border-radius: 30px;
}
.boxPosotionRight{
  position: absolute;
  right: 0px;
  width: 600px;
  padding: 10px;
}
.boxLogin {
  margin: auto;
  border: 2px solid #DAE2ED;
  border-radius: 45px;
  width: 587px;
  height: 470px;
  padding: 30px;
}

.fieldText {
  font-size: large;
  text-align: center;
  width: 100%;
  font-weight: bold;
  padding: 20px;
}

.MuiFormControlLabel-label {
  margin: 0;
  color: #47525E;
  font-size: 26px;
  line-height: 33px;
  text-align: left;
}
.MuiTextField-root{
  width: 100%;
  height: 80px;
}
.MuiFormLabel-root{
  font-size: 26px;
}
.MuiInputBase-root{
  font-size: 26px;
  background-color: #E6F1FF;
}
.main {
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #DAE2ED;
  border-radius: 45px;
  padding: 15px;
  
}
.itemProcess{
  border: 2px solid #DAE2ED;
  border-radius: 25px;
  height: 140px;
  width: 100%;
  display: flex;
  align-items: center; 
  justify-content: center; 
  color: #47525E;
  font-size: 26px;
  font-weight: bold;
}
.MuiButton-outlined{   
  height: 60px;
  
}
.boxProcess{
  margin-left: 10px;
  padding: 25px;
  font-size: 18px;
  border: 2px solid #8492A6;
  background-color: #FCFCFC;
  border-radius: 30px;
  width: 500px;
}
.SwitchProcess{
  margin-left: 10px;
}
.boxFoot{
  height: 85px;
  width: 1100px;
  display: flex;
  align-items: center; 
  justify-content: center; 
  color: #47525E;
  font-size: 26px;
  font-weight: bold;
  
}
.boxOnline{
  border: 2px solid #DAE2ED;
  border-radius: 25px;
  width: 200px;
  display: flex;
  align-items: center; 
  justify-content: center; 
  color: #47525E;
  font-size: 26px;
  font-weight: bold;
  margin-right: 30px;
}
.boxOn{
  background-color: #77D353;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.boxOff{
  background-color: #dd1818;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.boxPing{
  width: 100px;
  color: #47525E;
  font-size: 16px;
  margin-left: 30px;
  margin-right: 30px;
}
.boxLicence{
  margin-left: 30px;
}
.boxIcon{
 align-items: flex-start;
}
.boxForm{
  border: 2px solid #DAE2ED;
  border-radius: 25px;
  height: 450px;
  width: 100%;
  display: flex;
  align-items: center; 
  justify-content: center; 
  color: #47525E;
  font-size: 26px;
  font-weight: bold;
}
.boxTitleForm{
  display: flex;
  align-items: center; 
  justify-content: left; 
  border: 2px solid #DAE2ED;
  border-radius: 25px;
  height: 50px;
  color: #47525E;
  font-size: 26px;
  font-weight: bold;
  padding: 10px;
}
.textLabel{
  color: #47525E;
  font-size: 26px;
  font-weight: bold;
  padding: 10px;
}
.MuiInputLabel-filled{
  color: #47525E;
  font-size: 26px;
  font-weight: bold;
  padding: 10px;
}
.linFormOrder{
  height: 45px;
  float: right;
}
.headerTotals{
  float: right;
  text-align: end;
  width: 100%;
}
.textHerder{
  text-decoration: underline;
  margin-right: 60px;
  cursor: pointer;
}
.textHerderBlue{
  text-decoration: underline;
  margin-right: 60px;
  cursor: pointer;
  color: #00A6FF;
}
.itemLog{
  border: 2px solid #DAE2ED;
  border-radius: 25px;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center; 
  justify-content: center; 
  color: #47525E;
  font-size: 26px;
  font-weight: bold;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.textError{
  color: #dd1818;
  text-align: left;
  width: 150px;
}
